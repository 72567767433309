import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useState } from 'react';
import ThemeContext from '../context/theme/ThemeContext';
import ChatGPTContext from '../context/chatgpt/ChatGptContext';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  const { theme } = useContext(ThemeContext);
  const { submitForm } = useContext(ChatGPTContext);
  const [active, setActive] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vals, setVals] = useState({
    name: '',
    email: '',
    message: '',
  });
  const { name: nameVal, email, message } = vals;
  return (
    <div
      className={`primary middle-align center-align responsive !min-h-screen sm:!pl-[80px] ${
        theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
      }`}
    >
      <Helmet>
        <meta name="title" content="Acronotes - Contact" />
        <meta name="description" content="Acronotes - Contact" />
        <meta property="og:title" content="Acronotes - Contact" />
        <meta property="og:description" content="Acronotes - Contact" />
        <meta property="og:url" content="https://acronotes.com/contact" />
        <title>Acronotes - Contact us</title>
      </Helmet>
      {active && (
        <div
          className={`toast red active !cursor-default [justify-content:space-between] sm:!ml-[48px]`}
        >
          <span className="items-center justify-items-center">
            <i className="mr-2">error</i>
            <span className="mb-1 inline-block cursor-text">
              The form could not be submitted. Try again.
            </span>
          </span>
          <i className="cursor-pointer" onClick={() => setActive(false)}>
            close
          </i>
        </div>
      )}
      {!loading && !success && (
        <AnimatePresence>
          <motion.article
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            className="primary-container round no-shadow !mx-10 !-mt-px block !w-[100%] p-14 text-center sm:!mx-5 md:!mx-28 lg:!mx-60"
          >
            <h1 className="center-align small">Contact us</h1>
            <form
              name="contact"
              onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                const formSub = await submitForm(vals);
                setVals({
                  nameVal: '',
                  email: '',
                  message: '',
                });
                formSub == 'active' && setActive(true);
                formSub == 'active' &&
                  setTimeout(() => setActive(false), 10000);
                setSuccess(true);
                setLoading(false);
              }}
            >
              <div className="field label round border">
                <input
                  value={nameVal}
                  onChange={({ target: { value } }) =>
                    setVals({ ...vals, name: value })
                  }
                  type="text"
                  name="name"
                />
                <label htmlFor="name">Your Full Name</label>
              </div>
              <div className="field label round border">
                <input
                  value={email}
                  onChange={({ target: { value } }) =>
                    setVals({ ...vals, email: value })
                  }
                  type="email"
                  name="email"
                />
                <label htmlFor="email">Your Email</label>
              </div>
              <div className="field textarea label round border">
                <textarea
                  value={message}
                  onChange={({ target: { value } }) =>
                    setVals({ ...vals, message: value })
                  }
                  name="msg"
                ></textarea>
                <label htmlFor="msg">Message</label>
              </div>
              <button
                className="extend active square round round extra mt-4"
                type="submit"
              >
                <i>widgets</i>
                <span>Submit</span>
              </button>
              <button
                className="extend active error square round round extra mt-4"
                type="button"
                onClick={() =>
                  setVals({
                    nameVal: '',
                    email: '',
                    message: '',
                  })
                }
              >
                <i>cancel</i>
                <span>Reset</span>
              </button>
            </form>
          </motion.article>
        </AnimatePresence>
      )}
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={2}
            className="center-align middle-align center 
            middle
            absolute flex
            [align-items:center] [align-self:center] [justify-content:center] [justify-self:center]"
          >
            <span className="loader large white center-align middle-align center middle absolute"></span>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!loading && success && (
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={2}
            className="center-align middle-align center 
            middle
            absolute flex
            [align-items:center] [align-self:center] [justify-content:center] [justify-self:center]"
          >
            <div className="large center-align center middle absolute !text-green-500">
              <i className="extra center-align block !h-[100%] !w-[100%] rounded-full !bg-white !text-green-500 [font-size:6rem]">
                done
              </i>

              <h1 className="small !text-white">Submitted successfully</h1>
              <button
                className="extend active square fill round round extra mt-4"
                type="button"
                onClick={() => setSuccess(false)}
              >
                <i>widgets</i>
                <span>Submit another</span>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default NotFound;
