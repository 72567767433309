import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ChatGPTContext from '../context/chatgpt/ChatGptContext';
import ThemeContext from '../context/theme/ThemeContext.js';
import { Helmet } from 'react-helmet';
import AcronotesCard from '../components/AcronotesCard';

function Application() {
  const [notes, setNotes] = useState('');
  const [acronote, setAcronote] = useState('');
  const [loading, setLoading] = useState(false);
  const { generateAcronote, canBeParsed, wordCount, can } =
    useContext(ChatGPTContext);
  const { theme } = useContext(ThemeContext);
  const [className, setClassName] = useState('');
  const [copied, setCopied] = useState(false);
  const [active, setActive] = useState(false);

  console.log(acronote);

  console.log(can(() => JSON.parse(acronote).full, true));

  return (
    <div
      className={`primary responsive middle-align absolute top-0 !min-h-screen sm:!pl-[80px] ${
        theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
      }`}
      key="YO DEF"
    >
      <Helmet>
        <meta
          name="title"
          content="Acronotes - Ultimate AI Acronym Generator, Note Organizer, & Note creator"
        />
        <meta
          name="description"
          content="Revolutionize your learning with Acronotes, an AI Acronym Generator Tool that Transforms Lengthy Notes into Catchy Acronyms for Easy Recall. Try it now!"
        />
        <meta
          name="keywords"
          content="Acronotes, Noterator, Organotes, Notes, Note, AI, Acronym, Education Software, Learning Tool, Study Aid, AI for Education, Memorization Tool, Student Tools, Professional Tools, AI Acronym Generator, Organize My Notes, AI Organize Notes, Organize, AI Note Generator, AI Note Summarizer, Summarizer AI, Acronym Generator, Acronym Creator, Notes AI, Note AI"
        />
        <meta
          property="og:title"
          content="Acronotes - Ultimate AI Acronym Generator, Note Organizer, & Note Creator"
        />
        <meta
          property="og:description"
          content="Revolutionize your learning with Acronotes, an AI Acronym Generator Tool that Transforms Lengthy Notes into Catchy Acronyms for Easy Recall. Try it now!"
        />
        <meta property="og:image" content="../assets/logo.png" />
        <meta property="og:url" content="https://acronotes.com" />
        <meta name="twitter:card" content="../assets/logo.png" />
        <title>
          Acronotes - The Ultimate AI Acronym Generator, Note Organizer, & Note
          Creator
        </title>
      </Helmet>
      {active && (
        <div
          className={`toast red active !cursor-default [justify-content:space-between] sm:!ml-10`}
        >
          <span className="items-center justify-items-center">
            <i className="mr-2">error</i>
            <span className="mb-1 inline-block cursor-text">
              The Acronote could not be generated. Try again.
            </span>
          </span>
          <i className="cursor-pointer" onClick={() => setActive(false)}>
            close
          </i>
        </div>
      )}
      <AnimatePresence>
        {!acronote && !loading && (
          <motion.article
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={4}
            className="primary-container round no-shadow !mx-10 !-mt-px block !w-[100%] p-14 text-center sm:!mx-5 md:!mx-28 lg:!mx-60"
            style={{
              position: 'relative',
            }}
          >
            <h1 className="center-align">Generate</h1>
            <h6 className="center-align">
              Say goodbye to messy notes, say hello to Acronotes
            </h6>
            <div className="space"></div>
            {/* <div className="space"></div> */}
            <form
              action=""
              onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                const acronote = await generateAcronote(
                  { notes },
                  { timeout: 15000 }
                );
                if (canBeParsed(acronote) && acronote !== null) {
                  setAcronote(acronote);
                  setNotes('');
                } else {
                  setActive(true);
                  setTimeout(() => setActive(false), 10000);
                  console.log(acronote);
                  console.log(canBeParsed(acronote));
                  console.log(acronote !== null);
                }
                setLoading(false);
              }}
            >
              <div
                className={`field textarea round label ${className} ${
                  wordCount(notes) > 3500 ? 'invalid' : ''
                } border`}
              >
                <textarea
                  placeholder=" "
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.altKey) {
                      if (event.key === 'ArrowUp') {
                        const classname = '';
                        setClassName(classname);
                      } else if (event.key === 'ArrowDown') {
                        const classname = '!h-80';
                        setClassName(classname);
                      }
                    }
                  }}
                ></textarea>
                <label>Enter your notes here</label>
                <span
                  className={wordCount(notes) > 3500 ? 'error' : 'helper'}
                >{`Word count: ${wordCount(notes)}/3500`}</span>
              </div>
              {notes && (
                <AnimatePresence>
                  <motion.button
                    className="round fill border"
                    type="button"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -50, opacity: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const classname = !className ? '!h-80' : '';
                      setClassName(classname);
                    }}
                  >
                    {!className ? (
                      <i>arrow_circle_down</i>
                    ) : (
                      <i>arrow_circle_up</i>
                    )}
                  </motion.button>
                </AnimatePresence>
              )}
              <div className="space"></div>
              <button
                className={`square round round ${
                  notes && 'active'
                } extend center-align disabled:pointer-events-none`}
                disabled={wordCount(notes) > 3500 || !notes}
                type="submit"
              >
                <i>list</i>
                <span>Generate</span>
              </button>
            </form>
          </motion.article>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={2}
            className="center-align middle-align center 
            middle
            absolute flex
            [align-items:center] [align-self:center] [justify-content:center] [justify-self:center]"
          >
            <span className="loader large white center-align middle-align center middle absolute"></span>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="space"></div>

      <AnimatePresence>
        {acronote && !loading && (
          <AcronotesCard
            acronote={acronote}
            clearNotes={() => {
              setAcronote('');
              setNotes('');
            }}
          />
        )}
      </AnimatePresence>

      <div className="large-space"></div>
    </div>
  );
}
export default Application;
