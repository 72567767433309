import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ChatGPTContext from '../context/chatgpt/ChatGptContext.js';
import ThemeContext from '../context/theme/ThemeContext.js';
import Helmet from 'react-helmet';
import OrganotesCard from '../components/OrganotesCard.jsx';

function Organotes() {
  const [notes, setNotes] = useState('');
  const [organote, setOrganote] = useState('');
  const [loading, setLoading] = useState(false);
  const { generateOrganote, canBeParsed, wordCount } =
    useContext(ChatGPTContext);
  const { theme } = useContext(ThemeContext);
  const [className, setClassName] = useState('');
  const [copied, setCopied] = useState(false);
  const [active, setActive] = useState(false);

  console.log(organote);

  return (
    <div
      className={`primary responsive middle-align absolute top-0 !min-h-[100dvh] sm:!pl-[80px] ${
        theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
      }`}
      key="YO DEF"
    >
      {active && (
        <div
          className={`toast red active !cursor-default [justify-content:space-between] sm:!ml-[48px]`}
        >
          <span className="items-center justify-items-center">
            <i className="mr-2">error</i>
            <span className="mb-1 inline-block cursor-text">
              The Organote could not be generated. Try again.
            </span>
          </span>
          <i className="cursor-pointer" onClick={() => setActive(false)}>
            close
          </i>
        </div>
      )}
      <Helmet>
        <meta
          name="title"
          content="Organotes - Your new and improved AI-powered notes"
        />
        <meta
          name="description"
          content="Revolutionize your notetaking with Organotes, an AI-Powered Note Organization Tool that categorizes your vast collection of notes into a tidy, logical structure for quick retrieval and optimal comprehension. Engage with the next step of note management. Try Organotes today!"
        />
        <meta
          name="keywords"
          content="Organotes, Notes, Note, AI, Education Software, Learning Tool, Study Aid, AI for Education, Memorization Tool, Student Tools, Professional Tools, AI Organize Notes, Organize, Note Organizer, AI Note Organizer, Summarizer AI, Organize My Notes, Notes AI, Note AI"
        />
        <meta
          property="og:title"
          content="Organotes - Your new and improved AI-powered notes"
        />
        <meta
          property="og:description"
          content="Revolutionize your notetaking with Organotes, an AI-Powered Note Organization Tool that categorizes your vast collection of notes into a tidy, logical structure for quick retrieval and optimal comprehension. Engage with the next step of note management. Try Organotes today!"
        />
        <meta property="og:image" content="../assets/logo.png" />
        <meta property="og:url" content="https://acronotes.com/organotes" />
        <meta name="twitter:card" content="../assets/logo.png" />
        <title>Organotes - Your new and improved AI-powered notes</title>
      </Helmet>
      <div className="large-space"></div>
      <AnimatePresence>
        {!organote && !loading && (
          <motion.article
            initial={{ x: -50, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -50, opacity: 0 }}
            key={4}
            className="primary-container round no-shadow !mx-10 !my-1 block !w-[100%] p-14 text-center sm:!mx-5 md:!mx-28 lg:!mx-60"
            style={{
              position: 'relative',
            }}
          >
            <h1 className="center-align">Organotes</h1>
            <h6 className="center-align">Your new and improved notes</h6>
            <div className="space"></div>
            {/* <div className="space"></div> */}

            <form
              action=""
              onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                const organote = await generateOrganote(
                  { notes },
                  { timeout: 30000 }
                );
                if (organote !== null && organote !== 'error') {
                  setOrganote(organote);
                  setNotes('');
                } else {
                  console.log(
                    organote !== null,
                    organote !== 'error',
                    canBeParsed(organote)
                  );
                  setActive(true);
                  setTimeout(() => setActive(false), 10000);
                }
                setLoading(false);
              }}
            >
              <div
                className={`field textarea round label ${className} ${
                  wordCount(notes) > 3500 ? 'invalid' : ''
                } border`}
              >
                <textarea
                  // className="[clip-path:polygon(0%_0%,_1.25rem_0%,_1.25rem_0.5rem,_8.8125rem_0.5rem,_8.8125rem_0%,_100%_0%,_100%_100%,_0%_100%)] empty:[clip-path:none] focus:[clip-path:polygon(0%_0%,_1.25rem_0%,_1.25rem_0.5rem,_8.8125rem_0.5rem,_8.8125rem_0%,_100%_0%,_100%_100%,_0%_100%)]"
                  placeholder=" "
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  onKeyDown={async (event) => {
                    if (event.altKey) {
                      if (event.key === 'ArrowUp') {
                        const classname = '';
                        setClassName(classname);
                      } else if (event.key === 'ArrowDown') {
                        const classname = '!h-80';
                        setClassName(classname);
                      }
                    }
                  }}
                ></textarea>
                <label>Enter your notes here</label>
                <span
                  className={wordCount(notes) > 3500 ? 'error' : 'helper'}
                >{`Word count: ${wordCount(notes)}/3500`}</span>
              </div>
              {notes && (
                <AnimatePresence>
                  <motion.button
                    className="round fill border"
                    type="button"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -50, opacity: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const classname = !className ? '!h-80' : '';
                      setClassName(classname);
                    }}
                  >
                    {!className ? (
                      <i>arrow_circle_down</i>
                    ) : (
                      <i>arrow_circle_up</i>
                    )}
                  </motion.button>
                </AnimatePresence>
              )}
              <div className="space"></div>
              <button
                className={`square round ${
                  notes ? 'active' : ''
                } extend center-align disabled:pointer-events-none`}
                disabled={wordCount(notes) > 3500 || !notes}
                type="submit"
              >
                <i>subject</i>
                <span>Generate</span>
              </button>
            </form>
          </motion.article>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={2}
            className="center-align middle-align center 
            middle
            absolute flex
            [align-items:center] [align-self:center] [justify-content:center] [justify-self:center]"
          >
            <span className="loader large white center-align middle-align center middle absolute"></span>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="space"></div>

      <AnimatePresence>
        {organote && !loading && (
          <OrganotesCard
            organote={organote}
            clearNotes={() => {
              setOrganote('');
              setNotes('');
            }}
          />
        )}
      </AnimatePresence>

      <div className="large-space"></div>
    </div>
  );
}
export default Organotes;
