import { createContext, useReducer, useEffect } from 'react';
import themeReducer from './themeReducer';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const getDefaultTheme = () => {
    const lsTheme = localStorage.getItem('theme');
    if (lsTheme) {
      return lsTheme;
    }

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark';
    } else {
      return 'light';
    }
  };

  const initialState = {
    theme: getDefaultTheme(),
  };

  const [{ theme }, dispatch] = useReducer(themeReducer, initialState);

  useEffect(() => {
    document.documentElement.className = theme;
    document.body.className = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider
      className={theme}
      value={{
        theme,
        getDefaultTheme,
        swapTheme: () => {
          dispatch({ type: 'SWAP_THEME' });
          return theme == 'dark' ? 'light' : 'dark';
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
