import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import ThemeContext from '../context/theme/ThemeContext';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`primary middle-align center-align responsive !min-h-screen sm:!pl-[80px] ${
        theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
      }`}
    >
      <Helmet>
        <meta name="title" content="Acronotes - Not Found" />
        <meta name="description" content="Acronotes - 404 - Not Found" />
        <meta property="og:title" content="Acronotes - Not Found" />
        <meta property="og:description" content="Acronotes - 404 - Not Found" />
        <meta property="og:url" content="https://acronotes.com/404" />
        <title>
          Acronotes - The Ultimate AI Acronym Generator, Note Organizer, & Note
          Creator
        </title>
      </Helmet>
      <AnimatePresence>
        <motion.article
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -50, opacity: 0 }}
          className="primary-container round no-shadow !mx-10 !my-1 block p-14 text-center sm:!mx-5 md:!mx-60  lg:!mx-60"
        >
          <h1 className="center-align">404</h1>
          <h5 className="center-align">Page Not Found</h5>
          <div className="small-space"></div>
          <div className="center-align">
            <Link to="/">
              <button className="extend square round round">
                <i>home</i>
                <span>Back to Home</span>
              </button>
            </Link>
          </div>
        </motion.article>
      </AnimatePresence>
    </div>
  );
};
export default NotFound;
