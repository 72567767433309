import { AnimatePresence, motion } from 'framer-motion';

function OrganotesCard({ organote, clearNotes }) {
  return (
    <AnimatePresence>
      {organote && (
        <motion.article
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -50, opacity: 0 }}
          key={1}
          className={
            'primary-container no-shadow center-align middle-align !mx-0 !-mt-px block !min-h-[100dvh] !w-[100%] !break-words !rounded-none p-14 text-center'
          }
        >
          <div className="center-align !w-[100%]">
            <div
              className="org-card"
              dangerouslySetInnerHTML={{ __html: organote }}
            ></div>
            <div className="space"></div>
            <button
              className="extend square active round round center-align"
              onClick={(e) => {
                clearNotes('');
              }}
            >
              <i>cached</i>
              <span>Try another one</span>
            </button>
          </div>
        </motion.article>
      )}
    </AnimatePresence>
  );
}
export default OrganotesCard;
