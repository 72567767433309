import './css/App.css';
import userIcon from './assets/abstract-user-flat-3.svg';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navigation from './components/layout/Nav';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import Application from './pages/Application';
import Organotes from './pages/Organotes';
import Noterator from './pages/Noterator';
import { ChatGPTProvider } from './context/chatgpt/ChatGptContext';
import { ThemeProvider } from './context/theme/ThemeContext';
import Contact from './pages/Contact';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  ReactGA.initialize('G-Y2479FCC9W');

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, [window.location]);
  return (
    <ChatGPTProvider>
      <ThemeProvider>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/application" element={<Application key={0} />} />
            <Route path="/organotes" element={<Organotes key={0} />} />
            <Route path="/noterator" element={<Noterator key={0} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" exact element={<Landing />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ChatGPTProvider>
  );
}

export default App;
