export default (state, action) => {
  switch (action.type) {
    case 'NEW_MESSAGES':
      return {
        ...state,
        messages: [...state.messages],
        loading: false,
      };
    case 'NEW_ORGANOTE':
      return {
        ...state,
        organoteMessages: [...state.organoteMessages],
        loading: false,
      };
    case 'NEW_NOTES':
      return {
        ...state,
        noteratorMessages: [...state.noteratorMessages],
        loading: false,
      };
    case 'LOADING':
      return {
        ...state,
        loading: state.loading === false ? true : false,
      };
    default:
      return state;
  }
};
