import { createContext, useReducer } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import conversation from '../../assets/conversation.json';
import organotesConversation from '../../assets/organotes.json';
import noteratorConversation from '../../assets/noterator.json';
import chatGptReducer from './ChatGptReducer';
// const { encoding_for_model } = require('@dqbd/tiktoken');
// const tokenizer = encoding_for_model('gpt-3.5-turbo');

console.log(conversation);

const ChatGPTContext = createContext();

export default ChatGPTContext;

export const ChatGPTProvider = ({ children }) => {
  const initialState = {
    loading: false,
    messages: [...conversation],
    organoteMessages: [...organotesConversation],
    noteratorMessages: [...noteratorConversation],
  };
  const [state, dispatch] = useReducer(chatGptReducer, initialState);

  const can = (canIDoThis, shouldIReturn = false, errorMessage = false) => {
    try {
      const thingITried = canIDoThis();
      return shouldIReturn ? thingITried : true;
    } catch {
      return errorMessage;
    }
  };

  const baseUrl = 'https://acronotes-backend.azurewebsites.net';
  // const baseUrl = 'http://localhost:7072';

  const configuration = new Configuration({
    apiKey: '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',
  });

  const openai = new OpenAIApi(configuration);

  const wordCount = (text) => {
    if (!text) {
      return 0;
    }
    return text.trim().split(/\s+/).length;
  };

  const fetchChatGPTResponse = async ({ notes }, opts) => {
    try {
      console.log(notes);
      dispatch({
        action: 'LOADING',
      });

      const userMessage = `Give me an acronym for the following notes.
    
    Notes: 
    
    ${notes}`;
      console.log({
        body: JSON.stringify({
          notes,
        }),
      });

      const res = await fetch(`${baseUrl}/generate/acronote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          notes,
          // notes: 'hello world',
        }),
      });
      const assistantMessage = await res.json();
      console.dir(JSON.parse(assistantMessage));
      console.log(JSON.parse(assistantMessage).full);

      if (JSON.parse(assistantMessage) && JSON.parse(assistantMessage).detail) {
        return JSON.parse(assistantMessage).detail;
      }

      dispatch({
        type: 'NEW_MESSAGES',
        payload: [
          { role: 'user', content: userMessage },
          { role: 'assistant', content: JSON.parse(assistantMessage) },
        ],
      });
      if (!can(() => JSON.parse(assistantMessage).full)) {
        return 'error';
      }
      return assistantMessage;
    } catch (error) {
      console.log('there was an err');
      console.error('err: ', error);
      return error;
    }
  };

  const generateOrganote = async ({ notes }, opts) => {
    try {
      dispatch({
        action: 'LOADING',
      });

      const options = opts ? opts : {};

      const res = await fetch(`${baseUrl}/generate/organote`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          notes,
        }),
      });
      const assistantMessage = await res.json();
      console.log(assistantMessage);

      if (
        assistantMessage.detail &&
        (assistantMessage.detail.includes('Error') ||
          assistantMessage.detail.includes('HTML'))
      ) {
        return assistantMessage.detail;
      }

      dispatch({
        type: 'NEW_ORGANOTE',
        payload: [
          { role: 'user', content: notes },
          { role: 'assistant', content: assistantMessage },
        ],
      });
      return assistantMessage;
    } catch (error) {
      console.error(error);
      return 'error';
    }
  };

  const generateNotes = async ({ topic, lecture }, type, opts) => {
    try {
      dispatch({
        action: 'LOADING',
      });

      console.log(topic);
      console.log(lecture);
      console.log(type);

      const params = lecture
        ? new URLSearchParams({
            lecture,
            type,
          })
        : new URLSearchParams({
            type,
          });

      const res = await fetch(`${baseUrl}/generate/noterator?${params}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          topic,
        }),
      });
      let assistantMessage = await res.json();

      if (type === 'acronote') {
        assistantMessage = JSON.stringify(assistantMessage);
      }

      console.log(assistantMessage);

      dispatch({
        type: 'NEW_NOTES',
        payload: [
          {
            role: 'user',
            content: `Topic: ${topic} ${lecture ? `Lecture: ${lecture}` : ''} `,
          },
          { role: 'assistant', content: assistantMessage },
        ],
      });
      return assistantMessage;
    } catch (error) {
      console.log(error);
      return 'error';
    }
  };

  const submitForm = async (body) => {
    try {
      await fetch(`${baseUrl}/submit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      return 'success';
    } catch (error) {
      console.log(error);
      return 'error';
    }
  };

  return (
    <ChatGPTContext.Provider
      value={{
        messages: state.messages,
        loading: state.loading,
        generateAcronote: fetchChatGPTResponse,
        generateOrganote,
        generateNotes,
        canBeParsed: function canBeParsed(jsonString) {
          try {
            JSON.parse(jsonString);
            return true;
          } catch (error) {
            return false;
          }
        },
        wordCount,
        submitForm,
        can,
      }}
    >
      {children}
    </ChatGPTContext.Provider>
  );
};
