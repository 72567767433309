import { Link, NavLink } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../context/theme/ThemeContext';
import { useAnimation, motion } from 'framer-motion';
import logo from '../../assets/logo.webp';

const Navigation = () => {
  const { theme, swapTheme } = useContext(ThemeContext);
  const [animationFinished, setAnimationFinished] = useState(true);
  const [desktopActive, setDesktopActive] = useState('hidden');

  const controls = useAnimation();

  useEffect(() => {
    setAnimationFinished(false);
    setTimeout(() => setAnimationFinished(true), 500);
    controls
      .start([desktopActive, desktopActive == 'active' && 'visible'])
      .then(() => setAnimationFinished(true));
  }, [desktopActive]);

  useEffect(() => setAnimationFinished(true), []);

  return (
    <>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.5,
            },
          },
          visible: {
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          },
        }}
        initial={['hidden']}
        animate={controls}
        className={`overlay !z-[100000] !h-[100dvh] !w-[100dvw] cursor-pointer ${
          animationFinished ? `${desktopActive}` : 'active'
        }`}
        onClick={() => setDesktopActive('hidden')}
      ></motion.div>
      <motion.dialog
        variants={{
          hidden: {
            x: -350,
            transition: {
              duration: 0.5,
              times: [0, 0.3, 0.8],
              ease: ['backIn'],
            },
          },
          active: {
            x: 0,
            transition: {
              duration: 0.5,
              times: [0, 0.3, 0.2],
              ease: ['backOut'],
            },
          },
        }}
        initial={['hidden']}
        animate={controls}
        className={`left !z-[100000] overflow-x-visible`}
        open={true}
      >
        <header className="fixed !h-10 !overflow-x-visible !bg-transparent !px-4 !pt-4">
          <nav>
            <button
              className="transparent circle large"
              onClick={() => setDesktopActive('hidden')}
            >
              <i>close</i>
            </button>
            <h5 className={`max`}>Tools</h5>
          </nav>
        </header>
        <div className="large-space"></div>
        <NavLink
          to="/application"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round bg-primary bg-opacity-10 text-white dark:text-black'
              : 'row round'
          }
          onClick={() => setDesktopActive('hidden')}
        >
          <i>list_alt</i>
          <span>Acronotes</span>
          <div className="max"></div>
          <div className="font-extralight">Generate Acronyms</div>
        </NavLink>
        <div className="space"></div>
        <NavLink
          to="/organotes"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round overflow-x-visible bg-primary bg-opacity-10 text-white dark:text-black'
              : 'row round overflow-x-visible'
          }
          onClick={() => setDesktopActive('hidden')}
        >
          <i>subject</i>
          <span>Organotes</span>
          <div className="max"></div>
          <div className="font-extralight">Organize your Notes</div>
        </NavLink>
        <div className="space"></div>
        <NavLink
          to="/noterator"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round bg-primary bg-opacity-10 text-white dark:text-black'
              : 'row round'
          }
          onClick={() => setDesktopActive('hidden')}
        >
          <i>history_edu</i>
          <span>Noterator</span>
          <div className="max"></div>
          <div className="font-extralight">Create Notes</div>
        </NavLink>
      </motion.dialog>
      <nav className="m l left overflow-y-auto overflow-x-visible !px-0 !pt-0">
        <Link to="/application" className="h-[fit-content] !w-[100%] p-1">
          <img
            className="round large ml-2 mt-1"
            src={logo}
            alt="Acronotes logo"
          />
          <span className="my-1 font-thin">Acronotes</span>
        </Link>
        <a
          href="#"
          className="!w-[50%]"
          onClick={(e) => {
            e.preventDefault();
            setDesktopActive('active');
          }}
        >
          <button className="square round hide-i p-3">
            <i>widgets</i>
          </button>
          <div className="h-1"></div>
          <span>Tools</span>
        </a>
        {/* <NavLink
          to="/signup"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>person_add</i>
          <span>Sign Up</span>
        </NavLink>
        <NavLink
          to="/login"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>person</i>
          <span>Sign In</span>
        </NavLink> */}
        <NavLink
          to="/"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>home</i>
          <span>Home</span>
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>phone</i>
          <span>Contact</span>
        </NavLink>
        <a
          href="#"
          className="absolute bottom-10 !w-[50%]"
          onClick={(e) => {
            e.preventDefault();
            console.log(swapTheme());
          }}
        >
          <button className="circle hide-i extra border p-3">
            <i>{theme + '_mode'}</i>
          </button>
          <div className="h-1"></div>
          <span>{theme.charAt(0).toUpperCase() + theme.slice(1)}</span>
        </a>
      </nav>
      <nav
        className="s bottom center-align !box-border !px-0"
        id="navigation-bar1"
      >
        {/* <NavLink
          to="/login"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>account_circle</i>
          <span>Sign In</span>
        </NavLink> */}
        {/* <div className="small-space"></div> */}
        <NavLink
          to="/contact"
          className={({ isActive, isPending }) => (isActive ? 'active' : '')}
        >
          <i>phone</i>
          <span>Contact</span>
        </NavLink>
        <NavLink
          to="/"
          className={`!mr-0 !w-[20%]   ${({ isActive, isPending }) =>
            isActive ? 'active' : ''}`}
        >
          <i>home</i>
          <span>Home</span>
        </NavLink>
        <button
          className="center-align square round round extend extra active !ml-0"
          onClick={(e) => {
            e.preventDefault();
            setDesktopActive('active');
          }}
        >
          <i>widgets</i>
          <span>Tools</span>
        </button>
        <Link to="/application" className="!h-[100%] !w-[20%] !px-1">
          <img
            className="round large ml-0 mr-1 mt-2"
            src={logo}
            alt="Acronotes logo"
          />
          <span className="my-1 -ml-3.5 font-normal">Acronotes</span>
        </Link>
        <a
          href="#"
          className="absolute bottom-24 right-5"
          onClick={(e) => {
            e.preventDefault();
            console.log(swapTheme());
          }}
        >
          <button className="circle hide-i extra border !border-none !bg-[var(--surface)] p-3">
            <i>{theme + '_mode'}</i>
          </button>
          <div className="tooltip small-space top">
            {theme.charAt(0).toUpperCase() + theme.slice(1)}
          </div>
        </a>
      </nav>
    </>
  );
};
export default Navigation;
