import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useState } from 'react';
import ThemeContext from '../context/theme/ThemeContext';
import logo from '../assets/logo.png';
import promo from '../assets/promolight.png';
import promod from '../assets/promodark.png';
import orgpromo from '../assets/orgpromo.png';
import orgpromod from '../assets/orgpromodark.png';
import notepromo from '../assets/notepromo.png';
import notepromodark from '../assets/notepromodark.png';
import { Helmet } from 'react-helmet';

const Landing = () => {
  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    if (open) {
      setOpen(false);
      return 'closed';
    } else {
      setOpen(true);
      return 'opened';
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Acronotes - Ultimate AI Acronym Generator, Note Organizer, & Note creator"
        />
        <meta
          name="description"
          content="Revolutionize your learning with Acronotes, an AI Acronym Generator Tool that Transforms Lengthy Notes into Catchy Acronyms for Easy Recall. Try it now!"
        />
        <meta
          name="keywords"
          content="Acronotes, Noterator, Organotes, Notes, Note, AI, Acronym, Education Software, Learning Tool, Study Aid, AI for Education, Memorization Tool, Student Tools, Professional Tools, AI Acronym Generator, Organize My Notes, AI Organize Notes, Organize, AI Note Generator, AI Note Summarizer, Summarizer AI, Acronym Generator, Acronym Creator, Notes AI, Note AI"
        />
        <meta
          property="og:title"
          content="Acronotes - Ultimate AI Acronym Generator, Note Organizer, & Note Creator"
        />
        <meta
          property="og:description"
          content="Revolutionize your learning with Acronotes, an AI Acronym Generator Tool that Transforms Lengthy Notes into Catchy Acronyms for Easy Recall. Try it now!"
        />
        <meta property="og:image" content="../assets/logo.png" />
        <meta property="og:url" content="https://acronotes.com" />
        <meta name="twitter:card" content="../assets/logo.png" />
        <title>
          Acronotes - The Ultimate AI Acronym Generator, Note Organizer, & Note
          Creator
        </title>
      </Helmet>
      <div
        className={`overlay cursor-pointer${open ? ' active' : ''}`}
        onClick={toggleOpen}
      ></div>
      <dialog
        className={`modal center-align ![transition-duration:0.7s]`}
        id="modal"
        open={open}
      >
        <h5>
          <i className="!mr-2">widgets</i> Our tools
        </h5>
        <NavLink
          to="/application"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round bg-primary bg-opacity-10 text-white'
              : 'row round'
          }
          onClick={toggleOpen}
        >
          <i>list_alt</i>
          <span>Acronotes</span>
          <div className="max"></div>
          <div className="font-extralight">Generate Acronyms</div>
        </NavLink>
        <div className="space"></div>
        <NavLink
          to="/organotes"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round bg-primary bg-opacity-10 text-white'
              : 'row round'
          }
          onClick={toggleOpen}
        >
          <i>subject</i>
          <span>Organotes</span>
          <div className="max"></div>
          <div className="font-extralight">Organize your Notes</div>
        </NavLink>
        <div className="space"></div>
        <NavLink
          to="/noterator"
          className={({ isActive, isPending }) =>
            isActive
              ? 'active row round bg-primary bg-opacity-10 text-white'
              : 'row round'
          }
          onClick={toggleOpen}
        >
          <i>history_edu</i>
          <span>Noterator</span>
          <div className="max"></div>
          <div className="font-extralight">Create Notes</div>
        </NavLink>
        <nav className="right-align">
          <button className="border" onClick={toggleOpen}>
            Close
          </button>
        </nav>
      </dialog>
      <div
        // className={`primary middle-align !min-h-screen px-10 ${
        className={`lg:middle-align primary responsive max-lg:center-align !min-h-screen !bg-gradient-to-b !from-secondary-container !to-primary px-10 sm:!pl-20 sm:!pr-0 ${
          theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
        }`}
      >
        <AnimatePresence>
          <motion.div
            className="large-padding center-align middle-align mr-auto !w-[100%] max-lg:!pl-16 max-lg:!pr-0 max-md:!pl-12 max-sm:!pl-8 lg:!w-[50%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <img
              src={logo}
              alt="Acronotes logo"
              className="w-[75%] min-[800px]:w-[55%]"
            />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className="left-align max-sm:center-align responsive !w-[100%] text-white max-lg:!px-20 max-lg:!text-center max-md:!px-16 max-sm:!px-3 lg:!w-[50%] lg:!pr-20 lg:!text-left"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <h1 className="large !mx-auto !self-center !text-center ![transition:none] lg:!text-left">
              Say goodbye to messy notes, say hello to Acronotes
            </h1>
            <h2 className="small margin !mx-auto !mb-5 !self-center !text-center !text-3xl ![transition:none] max-lg:!text-center lg:!text-left">
              Acronotes is the ultimate way to memorize your notes. You can
              convert them to an easy to remember acronym to remember them
              anytime, anywhere.
            </h2>
            <div className="left-align max-lg:center-align">
              <button
                className="extend extra square round fill active "
                onClick={toggleOpen}
              >
                <i>list_alt</i>
                <span>Try it Now</span>
              </button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {/* <section className="responsive primary-container middle-align !min-h-[60vh] !w-[100vw] !overflow-visible !overflow-x-hidden !overflow-y-visible !bg-gradient-to-b from-primary from-0% via-teal-400 via-50% to-primary">
        <div className="center absolute left-[45%] h-72 w-72 animate-blob rounded-full bg-primary opacity-40 mix-blend-multiply blur-2xl filter"></div>
        <div className="animation-delay-2000 absolute left-[65%] h-72 w-72 animate-blob rounded-full bg-emerald-500 opacity-50 mix-blend-multiply blur-2xl filter"></div>
        <div className="container mx-auto !py-8">
          <h2 className="!ml-5 mb-4 text-2xl font-bold text-white dark:text-black">
            Features
          </h2>
          <div className="flex flex-wrap justify-between">
            <div className="w-full p-4 md:w-1/2 lg:w-1/3">
              <article className="round primary-container">
                <h3 className="mb-2 text-xl font-bold">
                  <i className="!mr-2">sentiment_satisfied</i>
                  Easy to use
                </h3>
                <p>
                  Acronotes is designed to be user-friendly and intuitive. It
                  allows you to easily generate and use organotes & acronotes!
                </p>
              </article>
            </div>
            <div className="w-full p-4 md:w-1/2 lg:w-1/3">
              <article className="round primary-container">
                <h3 className="mb-2 text-xl font-bold">
                  <i className="!mr-2">avg_time</i>
                  Lighting Fast
                </h3>
                <p>
                  All of our tools finish generating in under <b>30s</b>.
                  Acronotes finishes under <b>15s</b>. Organotes finishes under{' '}
                  <b>30s</b>.
                </p>
              </article>
            </div>
            <div className="!z-10 w-full p-4 md:w-1/2 lg:w-1/3">
              <article className="round primary-container">
                <h3 className="mb-2 text-xl font-bold">
                  <i className="!mr-2">public</i>
                  Access from anywhere
                </h3>
                <p>
                  Acronotes is cloud-based, so you can generate notes from
                  anywhere with internet.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className={`primary lg:middle-align responsive !min-h-screen !w-[100%] !overflow-hidden bg-gradient-to-b from-primary to-secondary-container !p-20 px-10 max-lg:!pr-0 max-lg:!pt-px max-sm:!px-0`}
      >
        <AnimatePresence>
          <motion.div
            className="large-padding center-align middle-align max-sm:!-pl-10 absolute max-lg:!-right-44 max-lg:!pl-20 max-lg:!pr-0 max-md:!pl-0 max-sm:!-right-28 lg:!left-10 lg:block lg:!w-[75%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <img
              src={theme == 'dark' ? promod : promo}
              alt={`Acronotes UI in ${theme} mode`}
              className="w-[100%] max-sm:!w-[125%]"
            />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className="center-align responsive !mx-10 to-40% dark:!text-white max-lg:mt-[38rem] max-md:mt-[34rem] max-sm:mt-[31rem] lg:absolute lg:right-0 lg:!m-0 lg:!w-[40%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <h1 className="large !mx-auto block !self-center !text-left ![transition:none] max-lg:!text-center">
              &nbsp;Acronotes
            </h1>
            <h2 className="small margin !mx-auto !mb-5 block !self-center !text-left !text-3xl ![transition:none] max-lg:!text-center">
              Tired of endless pages of notes? Losing track of key points in a
              sea of text? It's time to revolutionize your learning with
              Acronotes. Transform your lengthy notes into concise, catchy
              acronyms that stick in your mind like glue.
              {/* <br />
              <br /> */}
              {/* <span className="dark:!text-white">
                Using advanced technology, Acronotes scans your notes,
                identifies the core concepts, and forges them into unique,
                memorable acronyms that you can recall at a moment's notice.
                It's not just a tool, but your personal mnemonic device,
                tirelessly working to enhance your memory and learning.
              </span> */}
            </h2>
            <div className="left-align max-lg:!center-align">
              <Link to="/application">
                <button className="extend square round fill !bg-primary !text-on-primary">
                  <i>list_alt</i>
                  <span>Try it Now</span>
                </button>
              </Link>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>
      <section
        className={`secondary-container lg:middle-align responsive !min-h-screen !w-[100%] bg-gradient-to-t from-tertiary-container to-secondary-container !p-20 !pl-32 max-sm:!px-0`}
      >
        <AnimatePresence>
          <motion.div
            className="!w-[100%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <h1 className="small !mx-auto block !self-center !pl-2 !text-left ![transition:none] max-lg:!text-center">
              Why use Acronotes?
            </h1>
            <div className="margin #max-lg:!text-center !mx-auto !mb-5 block !self-center !pl-16 !text-left !text-2xl ![transition:none] max-sm:!px-3">
              <div className="row">
                <span className="min emoji">🎯</span>
                <div className="max">
                  <b>Brevity:</b> Long notes condensed into impactful acronyms.
                  Turn pages of text into a few memorable letters.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">🌈</span>
                <div className="max">
                  <b>Creativity:</b> Custom-made, fun, and easy-to-remember
                  acronyms crafted by our AI. Boring notes are a thing of the
                  past.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">💡</span>
                <div className="max">
                  <b>Recall:</b> Boost your memory recall and ace every test,
                  every presentation. Recall key concepts effortlessly during
                  exams, meetings, and presentations.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">⏱️</span>
                <div className="max">
                  <b>Efficiency:</b> Minimize reading, maximize understanding.
                  Save precious time by focusing on what's most important.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">👌</span>
                <div className="max">
                  <b>Simplicity:</b> User-friendly interface - upload your
                  notes, and let AcroNotes work its magic. No complicated steps,
                  no hassle.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">🎓</span>
                <div className="max">
                  <b>Learning Reinvented:</b> Traditional note-taking methods
                  can't compete with the innovative approach of Acronotes. We're
                  bringing note-taking into the 21st century.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">🚀</span>
                <div className="max">
                  <b>Study Smarter:</b> Acronotes isn't just about learning
                  faster, it's about learning smarter. By focusing on the key
                  points, you're preparing yourself to perform at the highest
                  level.
                </div>
              </div>
              <div className="row">
                <span className="min emoji">💼</span>
                <div className="max">
                  <b>Professional Advantage:</b> Acronotes isn't just for
                  students. Professionals can use it to remember important
                  points from meetings, seminars, or training sessions. Gain an
                  edge over your colleagues with Acronotes.
                </div>
              </div>
            </div>
            <div className="left-align max-lg:!center-align">
              <Link to="/application">
                <button className="extend active square round fill !bg-primary !text-on-primary">
                  <i>list_alt</i>
                  <span>Try it Now</span>
                </button>
              </Link>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>
      <section
        className={`tertiary-container responsive center-align middle-align !w-[100%]`}
      >
        <span className="round padding !h-fit border !border-primary !text-primary">
          <h4 className="!mb-0">Also featuring...</h4>
        </span>
      </section>
      <section
        className={`primary-container lg:middle-align responsive !min-h-[100dvh] !w-[100%] !overflow-hidden bg-gradient-to-t from-primary-container to-tertiary-container !p-20 px-10 max-lg:!pr-0 max-lg:!pt-px max-sm:!px-0`}
      >
        <AnimatePresence>
          <motion.div
            className="large-padding center-align middle-align absolute !-right-44 !pt-0 max-lg:!pl-20 max-md:!pl-0 max-sm:!-ml-28 lg:block lg:!w-[75%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <img
              src={theme == 'dark' ? orgpromod : orgpromo}
              alt={`Organotes UI in ${theme} mode`}
              className="w-[100%] max-sm:!-ml-10"
            />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className="center-align responsive !mx-10 dark:text-white max-lg:mt-[37rem] max-md:mt-[33rem] max-sm:mt-[30rem] lg:absolute lg:left-28 lg:!m-0 lg:!w-[40%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <h1 className="large !mx-auto block !self-center !text-right ![transition:none] max-lg:!text-center">
              &nbsp;Organotes
            </h1>
            <h2 className="small margin !mx-auto !mb-5 block !self-center !text-right !text-3xl ![transition:none] max-lg:!text-center">
              &nbsp;&nbsp;&nbsp;&nbsp;Are your notes scattered, unclear, and
              hard to follow? Do crucial details get lost in the shuffle? Time
              to experience the transformative power of Organotes. Bid farewell
              to cluttered chaos, and embrace the elegance of organized
              efficiency.
              {/* <br />
              <br /> */}
              {/* <span className="dark:!text-white">
                &nbsp;&nbsp;&nbsp;&nbsp;Using advanced technology, Organotes
                goes beyond the surface of your scribbles. It understands,
                organizes, and enhances your notes, turning them into a neatly
                structured, easy-to-understand format. Imagine having a personal
                librarian for your notes, creating a tailored system that not
                only clears the mess but also amplifies the value of your notes.
              </span> */}
            </h2>
            <div className="right-align max-lg:!center-align">
              <Link to="/organotes">
                <button className="extend active square round fill active !bg-primary !text-on-primary">
                  <i>list_alt</i>
                  <span>Try it Now</span>
                </button>
              </Link>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>
      <section
        className={`primary-container lg:middle-align responsive !min-h-screen !w-[100%] !overflow-hidden bg-gradient-to-t from-primary to-primary-container !p-20 px-10 max-lg:!pr-0 max-lg:!pt-px max-sm:!px-0`}
      >
        <AnimatePresence>
          <motion.div
            className="large-padding center-align middle-align max-sm:!-pl-10 absolute max-lg:!-right-44 max-lg:!pl-20 max-lg:!pr-0 max-md:!pl-0 max-sm:!-right-28 lg:!left-10 lg:block lg:!w-[75%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <img
              src={theme == 'dark' ? notepromodark : notepromo}
              alt={`Noterator UI in ${theme} mode`}
              className="w-[100%] max-sm:!w-[125%]"
            />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className="center-align responsive !mx-10 to-40% max-lg:mt-[38rem] max-md:mt-[34rem] max-sm:mt-[31rem] lg:absolute lg:right-0 lg:!m-0 lg:!w-[40%]"
            key={1}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
          >
            <h1 className="large !mx-auto block !self-center !text-left ![transition:none] max-lg:!text-center">
              &nbsp;Noterator
            </h1>
            <h2 className="small margin !mx-auto !mb-5 block !self-center !text-left !text-3xl ![transition:none] max-lg:!text-center">
              Struggling to keep up with information-dense lectures or articles?
              Looking for a way to make note-taking easier and more efficient?
              Introducing Noterator - your intelligent learning companion
              designed to turn complex text into tailor-made Acronotes or
              Organotes.
              {/* <br />
              <br /> */}
              {/* <span>
                Noterator utilizes advanced technology to analyze the text
                content of your lectures and articles, then produces neatly
                structured, meaningful notes in the format of your choice.
                Imagine having a personal academic assistant who not only
                simplifies your notes but also creates them in the style that
                best suits your learning preferences.
              </span> */}
            </h2>
            <div className="left-align max-lg:!center-align">
              <Link to="/application">
                <button className="extend active square round fill">
                  <i>list_alt</i>
                  <span>Try it Now</span>
                </button>
              </Link>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>
      <section className="responsive center-align primary !mt-0 !min-h-[70vh] bg-gray-300 bg-topography-light !py-8 dark:bg-topography-dark">
        <div className="center-align middle-align container mx-auto !min-h-[70vh] py-8">
          <div>
            <h3 className="center-align large mb-4 text-4xl font-bold">
              Get Started
            </h3>
            {/* <div className="flex flex-wrap justify-center">
            <div className="w-full p-4 md:w-1/2 lg:w-1/3">
              <article className="round primary-container"> */}
            <h5 className="center-align mb-2 text-2xl font-bold">
              Try it now!
            </h5>
            <h6 className="center-align text-xl">
              Acronotes is free to use. You can start now!
            </h6>
            <button
              className="extend active square round round fill extra mt-4"
              onClick={toggleOpen}
            >
              <i>widgets</i>
              <span>Our tools</span>
            </button>
          </div>
          {/* </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* <footer className="fixed bg-gray-800 p-4 text-white">
        <p className="text-center">
          &copy; 2023 Acronotes. All rights reserved.
        </p>
      </footer> */}
    </>
  );
};

export default Landing;
