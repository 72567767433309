import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ChatGPTContext from '../context/chatgpt/ChatGptContext.js';
import ThemeContext from '../context/theme/ThemeContext.js';
import OrganotesCard from '../components/OrganotesCard.jsx';
import AcronotesCard from '../components/AcronotesCard.jsx';
import { Helmet } from 'react-helmet';

function Noterator() {
  const [topic, setTopic] = useState('');
  const [lecture, setLecture] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const { generateNotes, wordCount } = useContext(ChatGPTContext);
  const { theme } = useContext(ThemeContext);
  const [className, setClassName] = useState('');
  const [active, setActive] = useState(false);

  console.log(notes);

  return (
    <div
      className={`primary responsive middle-align absolute top-0 !min-h-screen sm:!pl-[80px] ${
        theme == 'dark' ? ' bg-topography-dark' : ' bg-topography-light'
      }`}
      key="YO DEF"
    >
      {active && (
        <div
          className={`toast red active !cursor-default [justify-content:space-between] sm:!ml-[48px]`}
        >
          <span className="items-center justify-items-center">
            <i className="mr-2">error</i>
            <span className="mb-1 inline-block cursor-text">
              The notes could not be generated. Try again.
            </span>
          </span>
          <i className="cursor-pointer" onClick={() => setActive(false)}>
            close
          </i>
        </div>
      )}
      <Helmet>
        <meta
          name="title"
          content="Noterator - Your Personal AI-powered Note Creator"
        />
        <meta
          name="description"
          content="Transform your notetaking with Noterator, an AI Notetaking Tool that Converts Long Lectures & Documents into Crisp, Structured Notes for Enhanced Understanding. Embrace the future of learning. Try it now!"
        />
        <meta
          name="keywords"
          content="Noterator, Notes, Note, AI, Acronym, Education Software, Learning Tool, Study Aid, AI for Education, Memorization Tool, Student Tools, Professional Tools, AI Acronym Generator, Organize My Notes, AI Organize Notes, Organize, AI Note Generator, AI Note Summarizer, Summarizer AI, Acronym Generator, Acronym Creator, Notes AI, Note AI"
        />
        <meta
          property="og:title"
          content="Noterator - Your Personal AI-powered Note Creator"
        />
        <meta
          property="og:description"
          content="Transform your notetaking with Noterator, an AI Notetaking Tool that Converts Long Lectures & Documents into Crisp, Structured Notes for Enhanced Understanding. Embrace the future of learning. Try it now!"
        />
        <meta property="og:image" content="../assets/logo.png" />
        <meta property="og:url" content="https://acronotes.com/nterator" />
        <meta name="twitter:card" content="../assets/logo.png" />
        <title>Noterator - Your Personal AI-powered Note Creator</title>
      </Helmet>
      <div className="large-space"></div>
      <AnimatePresence>
        {!notes && !loading && (
          <motion.article
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={4}
            className="primary-container round no-shadow !mx-10 !my-1 block !w-[100%] p-14 text-center sm:!mx-5 md:!mx-28 lg:!mx-60"
            style={{
              position: 'relative',
            }}
          >
            <h1 className="center-align">Noterator</h1>
            <h6 className="center-align">Your personal note creator</h6>
            <div className="space"></div>
            {/* <div className="space"></div> */}
            <nav className="no-space center-align">
              <div
                className={`field input round label large !min-w-[50%] border`}
              >
                <input
                  // className="peer [clip-path:polygon(0%_0%,_1.25rem_0%,_1.25rem_0.5rem,_8.8125rem_0.5rem,_8.8125rem_0%,_100%_0%,_100%_100%,_0%_100%)] empty:[clip-path:none] focus:[clip-path:polygon(0%_0%,_1.25rem_0%,_1.25rem_0.5rem,_8.8125rem_0.5rem,_8.8125rem_0%,_100%_0%,_100%_100%,_0%_100%)]"
                  placeholder=" "
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                ></input>
                <label className="!ml-0 peer-placeholder-shown:!ml-3 peer-focus:!ml-0">
                  Enter your topic here
                </label>
              </div>
            </nav>
            <div className="space"></div>
            <div
              className={`field textarea round label ${className} ${
                wordCount(lecture) > 3500 ? 'invalid' : ''
              } border`}
            >
              <textarea
                placeholder=" "
                value={lecture}
                onChange={(e) => setLecture(e.target.value)}
                onKeyDown={async (event) => {
                  if (event.altKey) {
                    if (event.key === 'ArrowUp') {
                      const classname = '';
                      setClassName(classname);
                    } else if (event.key === 'ArrowDown') {
                      const classname = '!h-80';
                      setClassName(classname);
                    }
                  }
                }}
              ></textarea>
              <label>Enter your lecture here</label>
              <span
                className={wordCount(lecture) > 3500 ? 'error' : 'helper'}
              >{`Word count: ${wordCount(lecture)}/3500`}</span>
            </div>{' '}
            {lecture && (
              <AnimatePresence>
                <motion.button
                  className="round fill center-align !mx-auto mb-4 block border"
                  type="button"
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    const classname = !className ? '!h-80' : '';
                    setClassName(classname);
                  }}
                >
                  {!className ? (
                    <i>arrow_circle_down</i>
                  ) : (
                    <i>arrow_circle_up</i>
                  )}
                </motion.button>
              </AnimatePresence>
            )}
            <button
              className={`square round ${
                (lecture || topic) && 'active'
              } extend center-align disabled:pointer-events-none`}
              disabled={wordCount(lecture) > 3500 || (!lecture && !topic)}
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                setLoading(true);
                const notes = await generateNotes(
                  { topic, lecture: lecture ? lecture : null },
                  !checked ? 'acronote' : 'organote',
                  { timeout: 30000 }
                );
                if (notes !== null) {
                  setNotes(notes);
                  setLecture('');
                  setTopic('');
                } else {
                  setActive(true);
                  setTimeout(() => setActive(false), 10000);
                }
                setLoading(false);
              }}
            >
              <i>history_edu</i>
              <span>Generate</span>
            </button>
            <details>
              <summary className="wave round center-align !mx-auto !mt-3 !w-[190px] !p-4">
                Aditional Options
              </summary>
              <div>
                <div className="h-px"></div>
                <h6 className="center-align !mb-0 !text-base">
                  Generate results as...
                </h6>
                <div className="field middle-align !my-0">
                  <nav>
                    <div className="max">
                      <h6 className="right-align middle-align small">
                        Acronote
                      </h6>
                    </div>

                    <label className="switch icon middle-align">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      <span>
                        <i>done</i>
                      </span>
                    </label>

                    <div className="max middle-align">
                      <h6 className="small">Organote</h6>
                    </div>
                  </nav>
                </div>
              </div>
            </details>
          </motion.article>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -50, opacity: 0 }}
            key={2}
            className="center-align middle-align center 
            middle
            absolute flex
            [align-items:center] [align-self:center] [justify-content:center] [justify-self:center]"
          >
            <span className="loader large white center-align middle-align center middle absolute"></span>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="space"></div>

      <AnimatePresence>
        {notes && !loading && checked ? (
          <OrganotesCard organote={notes} clearNotes={() => setNotes('')} />
        ) : (
          notes &&
          !loading && (
            <AcronotesCard acronote={notes} clearNotes={() => setNotes('')} />
          )
        )}
      </AnimatePresence>

      <div className="large-space"></div>
    </div>
  );
}
export default Noterator;
