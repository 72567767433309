import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

function AcronotesCard({ acronote, clearNotes }) {
  const [copied, setCopied] = useState(false);
  console.log(acronote);
  return (
    <AnimatePresence>
      {acronote && (
        <motion.article
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -50, opacity: 0 }}
          key={1}
          className={
            'primary-container no-shadow center-align middle-align !mx-0 !-mt-px block !min-h-[100dvh] !w-[100dvw] !rounded-none p-14 text-center'
          }
        >
          <div className="center-align !w-[100%]">
            <h3 className="center-align">Acronote:</h3>
            <h5 className="center-align">
              <b>Acronym:</b>&nbsp;
              {JSON.parse(acronote).acronym}
            </h5>
            <div className="text-left text-xl">
              {JSON.parse(acronote).full.map((obj, i) => {
                return (
                  <div className="row" key={i}>
                    <i className="fill">radio_button_unchecked</i>
                    <div className="max">
                      <b>{Object.keys(obj)[0]}:</b> {Object.values(obj)[0]}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="space"></div>
            <button
              className="round fill border"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                const arr = JSON.parse(acronote).full.map((obj, i) => {
                  return `${Object.keys(obj)[0]}: ${Object.values(obj)[0]}`;
                });

                let string = `Acronym: ${JSON.parse(acronote).acronym}\n`;

                for (let i = 0; i < arr.length; i++) {
                  string += arr[i];
                  if (i < arr.length - 1) {
                    string += ', ';
                  }
                }

                const text = string.replaceAll(/, /g, '\n');

                console.log('text: ', text);

                navigator.clipboard
                  .writeText(text)
                  .then(() => {
                    console.log('Text copied to clipboard');
                  })
                  .catch((error) => {
                    console.error('Error copying text: ', error);
                  });
                setCopied(true);
                setTimeout(() => setCopied(false), 10000);
              }}
            >
              <i>content_copy</i>
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </button>
            <div className="space"></div>

            <button
              className="extend square round round center-align active"
              onClick={(e) => {
                clearNotes();
              }}
            >
              <i>cached</i>
              <span>Try another one</span>
            </button>
          </div>
        </motion.article>
      )}
    </AnimatePresence>
  );
}
export default AcronotesCard;
