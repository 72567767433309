export default (state, { type, payload }) => {
  switch (type) {
    case 'SWAP_THEME':
      localStorage.setItem('theme', state.theme == 'dark' ? 'light' : 'dark');
      return {
        ...state,
        theme: state.theme == 'dark' ? 'light' : 'dark',
      };
    default:
      return state;
  }
};
